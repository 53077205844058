<template>
  <v-dialog v-model="activeDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update service status</v-card-title>
      <v-card-text>

        <v-switch
            v-model="add.active"
            :label="`Active: ${add.active}`"
        ></v-switch>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.add.$invalid"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Restful from "../../services/RestFul.js"
export default {
  mixins: [Utils, validationMixin],
  props: {
    activeDialog: Boolean,
    myId: { type: Number },
  },
  data() {
    return {
      loading: false,
      disabled: false,
      menu2: null,
      add: {
        active: false,
      },
    };
  },
  validations: {
    add: {
      active: {
        required
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.proServices
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.add.active = response.data.active;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeActive", payload);
    },
    submit() {
      let active = this.add.active;
      let data = { active};

      let payload = { id: this.myId, data: data, state: false };
      console.log(payload)
      this.$emit("submitActive", payload);
    },
  },
};
</script>
