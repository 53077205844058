<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" text x-small @click="newDialog = true" v-on="on">
              new service
            </v-btn>
          </template>
          <span>New Service</span>
        </v-tooltip>
        <ProServiceNew :newDialog="newDialog" :myId="id" :myName="name" @closeNew="closeNew" @submitNew="submitNew" />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="refresh" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon :to="{ name: 'medics.services' }" v-on="on">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Back to Services</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-toolbar flat dense>
          <v-toolbar-title>
            {{ _decode(name) }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table :headers="headers" :items="myServicesList" :items-per-page="10" :loading="loading"
            :sort-by="['id']" :sort-desc="[true]" loader-height="1">
            <template v-slot:item.pricing="{ item }">
              {{ Number(item.pricing).toLocaleString() }}
            </template>

            <template v-slot:item.discounts="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on" @click.stop="$set(discDialog, item.id, true)">
                    {{ item.discounts > 0 ? item.discounts : 0 }}
                  </v-btn>
                </template>
                <span>{{ item.discounts > 0 ? "Update discount" : "Add discount" }}</span>
              </v-tooltip>
              <ProServiceDisc v-if="discDialog[item.id]" :discDialog="discDialog[item.id]" :myId="item.id"
                @closeDisc="closeDisc" @submitDisc="submitDisc" />
            </template>

            <template v-slot:item.active="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :color="item.active ? 'primary' : 'error'" dark elevation="0" fab x-small v-on="on"
                    @click.stop="$set(activeDialog, item.id, true)">
                    <v-icon>{{ item.active ? "mdi-check" : "mdi-close" }}</v-icon>
                  </v-btn>
                </template>
                <span>Service is {{ item.active ? "active" : "not active" }} for additional service</span>
              </v-tooltip>
              <ProServiceActive v-if="activeDialog[item.id]" :activeDialog="activeDialog[item.id]" :myId="item.id"
                @closeActive="closeActive" @submitActive="submitActive" />
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn color="primary" text x-small @click.stop="$set(viewDialog, item.id, true)">
                detail
              </v-btn>
              <ProServiceDetail v-if="viewDialog[item.id]" :myId="item.id" :viewDialog="viewDialog[item.id]"
                @closeView="closeView" />


              <v-btn color="success" text x-small @click.stop="$set(editDialog, item.id, true)">
                edit
              </v-btn>
              <ProServiceEdit v-if="editDialog[item.id]" :editDialog="editDialog[item.id]" :myId="item.id"
                @closeEdit="closeEdit" @submitEdit="submitEdit" />

              <v-btn color="red" text x-small @click.stop="$set(removeDialog, item.id, true)">
                remove
              </v-btn>
              <ProServiceRemove v-if="removeDialog[item.id]" :myId="item.id" :removeDialog="removeDialog[item.id]"
                @closeRemove="closeRemove" @submitRemove="submitRemove" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snack.bar" :color="snack.color" :multi-line="snack.multiLine">
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../mixins/utils";
import WinScroll from "../../mixins/windowscroll";
import ProServiceNew from "../../components/proServices/newone.vue";
import ProServiceDetail from "../../components/proServices/view.vue";
import ProServiceEdit from "../../components/proServices/edit.vue";
import ProServiceDisc from "../../components/proServices/discounting.vue";
import ProServiceRemove from "../../components/proServices/remove.vue";
import ProServiceActive from "../../components/proServices/activeDialong.vue";

export default {
  props: {
    id: { type: String },
    name: { type: String }
  },
  components: {
    ProServiceNew,
    ProServiceDisc,
    ProServiceDetail, ProServiceEdit,
    ProServiceRemove, ProServiceActive
  },
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Service",
          to: { name: "medics.services" },
        },
        {
          exact: true,
          link: true,
          text: "Service Detail",
          to: { name: "medics.services.add" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      newDialog: false,
      activeDialog: {},
      viewDialog: {},
      editDialog: {},
      discDialog: {},
      removeDialog: {},
      loading: false,
      myServicesList: [],
      headers: [
        { text: "name", value: "name" },
        { text: "description", value: "description" },
        { text: "pricing", value: "pricing" },
        { text: "discounts", value: "discounts" },
        { text: "active", value: "active", align: "center" },
        { text: "actions", value: "id", align: "center" },
      ],
    };
  },
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let id = parseInt(this._decode(this.id));
      console.log('id', id)
      let self = this;
      Restful.subscribers.proServices
        .findMeet(id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.myServicesList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    closeNew(a) {
      this.newDialog = a.state;
    },
    submitNew(a) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let self = this;
      Restful.subscribers.proServices
        .create(a.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.newDialog = a.state;
    },
    closeDisc(p) {
      this.$set(this.discDialog, p.id, p.state);
    },
    submitDisc(p) {
      console.log('p', p)
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let self = this;
      Restful.subscribers.proServices
        .updateDiscount(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.discDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let self = this;
      Restful.subscribers.proServices
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let self = this;
      Restful.subscribers.proServices
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeActive(p) {
      this.$set(this.activeDialog, p.id, p.state);
    },
    submitActive(p) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let self = this;
      Restful.subscribers.proServices
        .updateActive(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.activeDialog, p.id, p.state);
    },
  },
};
</script>
