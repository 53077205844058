<template>
  <v-dialog v-model="discDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update service</v-card-title>
      <v-card-text>
        <v-text-field v-model="add.discounts" dense label="discounts" max="100" min="0" type="number" :hint="discValue"
          persistHint></v-text-field>

        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" min-width="auto" offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="add.discount_expires" label="discount Expiry date" prepend-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="add.discount_expires" @input="menu2 = false"></v-date-picker>
        </v-menu>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="$v.add.$invalid" color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { decimal, minValue, maxValue, required } from "vuelidate/lib/validators";
import Restful from "../../services/RestFul.js"
import moment from "moment/moment";

export default {
  mixins: [Utils, validationMixin],
  props: {
    discDialog: Boolean,
    myId: { type: Number },
  },
  data() {
    return {
      loading: false,
      disabled: false,
      menu2: null,
      add: {
        discounts: 0,
        discount_expires: null,
      },
      discValue: null
    };
  },
  validations: {
    add: {
      discounts: {
        required, decimal, minValue: minValue(0), maxValue: maxValue(100)
      },
      discount_expires: {
        required
      },
    },
  },
  watch: {
    'add.discounts': function (a) {
      // console.log(a)
      this.discValue = a + "% deducted from base price"
    }
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.proServices
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.add.discounts = response.data.discounts;
          this.add.discount_expires = response.data.discount_expires === null ? moment().format("yyyy-MM-DD") : moment(response.data.discount_expires).format("yyyy-MM-DD");
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeDisc", payload);
    },
    submit() {
      let discounts = this.add.discounts;
      let discount_expires = Date.parse(this.add.discount_expires);
      let data = { discounts, discount_expires };

      let payload = { id: this.myId, data: data, state: false };
      console.log(payload)
      this.$emit("submitDisc", payload);
    },
  },
};
</script>
