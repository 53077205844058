<template>
  <v-dialog v-model="viewDialog" max-width="1000" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View Detail</v-card-title>
      <v-card-text>
        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">meeting type name</div>
              <div class="myright">{{ views.meeting_type_name | capitalize }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">service name</div>
              <div class="myright">{{ views.name | capitalize }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">description</div>
              <div class="myright">{{ views.description }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">pricing</div>
              <div class="myright">{{ currency(views.pricing) }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">discounts</div>
              <div class="myright">{{ views.discounts }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">discount expires</div>
              <div class="myright">{{ views.discount_expires | myDating }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">active</div>
              <div class="myright">{{ views.active ? "Yes" : "No" }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">created</div>
              <div class="myright">{{ views.created | myDating }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">updated</div>
              <div class="myright">{{ views.updated | myDating }}</div>
              <div class="clearall"></div>
            </li>
          </ul>
        </div>
        <br/>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
import Utils from "../../mixins/utils.js";

export default {
  props: {
    viewDialog: Boolean,
    myId: {type: Number, default: 0},
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      views: {
        name: null,
        description: null, pricing: null, discounts: null,
        discount_expires: null, active: null,
        created: null, updated: null, meeting_type_name: null,
      },
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.proServices
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.views.name = response.data.name;
            this.views.description = response.data.description;
            this.views.pricing = response.data.pricing;
            this.views.discounts = response.data.discount;
            this.views.discount_expires = response.data.discount_expires;
            this.views.active = response.data.active;
            this.views.created = response.data.created;
            this.views.updated = response.data.updated;
            this.views.meeting_type_name = response.data.meeting_type_name;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeView", payload);
    },
  },
};
</script>
