<template>
  <v-dialog v-model="newDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new service for {{ _decode(myName) }}</v-card-title>
      <v-card-text>
        <v-text-field v-model="add.name" dense label="name"></v-text-field>
        <v-text-field v-model="add.description" dense label="description"></v-text-field>
        <v-text-field v-model="add.pricing" dense label="pricing" type="number"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="$v.add.$invalid" color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { decimal, maxLength, required } from "vuelidate/lib/validators";
// import Restful from "@/services/RestFul";

export default {
  mixins: [Utils, validationMixin],
  props: {
    newDialog: Boolean,
    myId: { type: String },
    myName: { type: String },
  },
  data() {
    return {
      loading: false,
      disabled: false,
      add: { name: null, description: null, pricing: null },
    };
  },
  validations: {
    add: {
      name: {
        required,
        maxLength: maxLength(240),
      },
      description: {
        required,
        maxLength: maxLength(240),
      },
      pricing: {
        required, decimal
      },
    },
  },
  created() {
  },
  methods: {
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let my_meeting_typesid = parseInt(this._decode(this.myId));
      let name = this.add.name;
      let description = this.add.description;
      let pricing = parseInt(this.add.pricing);
      let data = { my_meeting_typesid, name, description, pricing };
      console.log("data:", data);
      let payload = { data: data, state: false };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
